/* GDPR Cookie dialog */

.gdprcookie {
    position: fixed;
    color: white;
    font-size: .8em;
    line-height: 1.5em;
    right: 1.5rem;
    bottom: 1.5rem;
    max-width: 25em;
    padding: 1rem;
    background: black;
}
.gdprcookie h1,
.gdprcookie h2 {
    font-size: 1.2em;
    margin-bottom: .5rem;
}
.gdprcookie h2 {
    font-size: 1.2em;
}
.gdprcookie a {
    color: inherit;
}


/* GDPR Cookie buttons */

.gdprcookie-buttons {
    text-align: center;
}
.gdprcookie-buttons button {
    color: white;
    font-family: inherit;
    font-size: 1em;
    padding: .4rem;
    border: solid .05rem currentColor;
    border-radius: .15rem;
    margin: 0 .5rem;
    background: none;
    cursor: pointer;
}
.gdprcookie-buttons button:disabled {
    color: rgba(255,255,255,.5);
}


/* GDPR Cookie types */

.gdprcookie-types ul {
    overflow: hidden;
    padding: 0;
    margin: 0 0 1rem;
}
.gdprcookie-types li {
    display: block;
    list-style: none;
    float: left;
    width: 50%;
    padding: 0;
    margin: 0;
}
.gdprcookie-types input[type=checkbox] {
    margin-right: .25rem;
}